<template>
  <div class="mb-5">

      <div  style="align-items:flex-start;">
        <h1 class="mb-2">My Payroll Hub</h1>
        <p>View timesheets, pay stubs, and manage your Everee account.</p>
      </div>
 
      <div class="dashboard__container--body pt-3">

        <div class="dashboard__container--body--col flex" v-if="userProfile.employeeId">
          <div class="hoverCard">

             <router-link to="/account/employee/home">
            <span class="flex justify-space-between">
              <h3 class="mb-2">Manage My Employee Payroll Account</h3>
            </span>
            <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/everee_logo.png?alt=media&token=b70aba8d-958b-4727-860c-8af6918581ab" alt="" style="margin: 1rem 0; height: 3rem;">
            <div>Manage your tax withholdings, direct deposit details, and paycard options.</div>
            </router-link>
          </div>
        </div>
        <div class="dashboard__container--body--col flex">
          <div class="hoverCard">
             <router-link to="/account/payroll/home">
            <span class="flex justify-space-between">
              <h3 class="mb-2">Manage My 1099 Payroll Account</h3>
            </span>
            <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/everee_logo.png?alt=media&token=b70aba8d-958b-4727-860c-8af6918581ab" alt="" style="margin: 1rem 0; height: 3rem;">
            <div>Manage your tax details, direct deposit details, and paycard options.</div>
            </router-link>
          </div>
        </div>


        <div class="dashboard__container--body--col flex" v-if="userProfile.employeeId && userProfile.employeeOnboardingComplete">
          <div class="hoverCard">
             <router-link :to="{name: 'employeePaystubs'}">
            <span class="flex justify-space-between">
              <h3 class="mb-2">My W2 Pay History</h3>
            </span>
            <div>See all of your paystubs and deposit information for your W2 earnings.</div>
            </router-link>
          </div>
        </div>
        

        <div class="dashboard__container--body--col flex">
          <div class="hoverCard">
             <router-link :to="{name: 'payrollPaystubs'}">
            <span class="flex justify-space-between">
              <h3 class="mb-2">My 1099 Pay History</h3>
            </span>
            <div>See all of your paystubs and deposit information for your 1099 earnings.</div>
            </router-link>
          </div>
        </div>

        <div class="dashboard__container--body--col flex">
          <Card status="blank" url="/account/payroll/timesheets" :title="`See My Timesheets`" :description="`See all of your hours worked in one simple report.`" />
        </div>

        <div class="dashboard__container--body--col flex">
          <div class="hoverCard">
            <a href="https://help.everee.com/s/topic/0TODu0000000499OAA/everee-for-workers" target="_blank"> <span class="flex justify-space-between">
              <h3 class="mb-2">Frequently Asked Questions</h3>
            </span>
            <div>Where is my money? How to set up a pay card? and more...</div>
            </a>
          </div>
        </div>


        <div class="dashboard__container--body--col flex">
          <div class="card" style="box-shadow: none; border: 2px solid #ddd; width:100%;">
            <span class="flex justify-space-between">
              <h3 class="mb-2">TIN/SSN Verification</h3>
            </span>
            <div v-if="userProfile.tinVerificationState">
              <span v-if="userProfile.tinVerificationState && userProfile.tinVerificationState == 'VERIFIED'">
                {{userProfile.tinVerificationState}}
              </span>
              <span v-if="userProfile.tinVerificationState && userProfile.tinVerificationState != 'VERIFIED'" class="danger">
                {{userProfile.tinVerificationState}} - Please update your Tax ID Number or Social Security Number in Everee right away.
              </span>
            
            </div>
          </div>
        </div>

        <div class="dashboard__container--body--col flex">
          <div class="card" style="box-shadow: none; border: 2px solid #ddd; width:100%;">
            <span class="flex justify-space-between">
              <h3 class="mb-2">Get Support</h3>
            </span>
            Please email support@jumpstaffing.com if you need additional help.
          </div>
        </div>

        <!-- <div class="dashboard__container--body--col flex">
          <div class="hoverCard">
             <router-link :to="{name: 'support'}">
            <span class="flex justify-space-between">
              <h3 class="mb-2">Get Support</h3>
            </span>
            <div>If you have any questions about your timesheets or payroll, please let us help.</div>
            </router-link>
          </div>
        </div> -->



        



        

      </div>
      
 
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Card from '@/components/Modules/Card.vue'

export default {
  name: 'payrollHub',
  // mounted() {
  //   this.userCheck()
  // },
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
  methods: {
    // userCheck() {
    //   if (this.userProfile && this.userProfile.evereeOnboardingComplete) {
    //     this.$router.push('/account/payroll/address')
    //   }
    // },
  },
   components: {
    Loader,
    Card
  },
}
</script>